import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { routerTransition } from 'src/app/router.animations';
import { AuthService } from '../../../services/auth.service';
import { REPORT_DATA } from 'src/app/shared/constants/report-data';

@Component({
  selector: 'take-step',
  templateUrl: './take-step.component.html',
  styleUrls: ['./take-step.component.scss'],
  animations: [routerTransition()],
})
export class TakeStepComponent implements OnInit {
  public loading: boolean = false;
  public challengeLevel;
  public challengeTask;
  public MainMenu: any;
  public menuData: any;
  public subMenuData: any;

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.GetStatus();
    const menu = localStorage.getItem('menu');
    const subMenu = localStorage.getItem('house_name');
    this.menuData = REPORT_DATA.find((o) => o?.key === menu);
    this.subMenuData = this.menuData.items?.find((o) => o?.key === subMenu);

    this.challengeLevel = localStorage.getItem('challengeLevel');
    this.getChallengeData();
  }
  GetStatus() {
    const obj = {
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
      token: localStorage.getItem('token'),
    };
    this.auth.growth_status(obj).subscribe(
      (res: any) => {
        this.MainMenu = res.Mainsubman;
      },
      (err: any) => {}
    );
  }
  getChallengeData() {
    const menuItem = REPORT_DATA.reduce(
      (items, o) => [...items, ...o?.items],
      []
    ).find((o) => o?.key === localStorage.getItem('house_name'));
    const obj = {
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: menuItem?.dMenu,
      dimensionsubmenu: menuItem?.dSubMenu,
      type: localStorage.getItem('challengeLevel'),
      token: localStorage.getItem('token'),
    };
    this.auth.growth_challenge_sub_task(obj).subscribe(
      (res: any) => {
        console.log(res.data);
        this.challengeTask = res.data;
      },
      (err: any) => {}
    );
  }
  navigate(link, subtaskid) {
    this.router.navigate(['./challenges', subtaskid], {
      relativeTo: this.route,
    });
    console.log(subtaskid);
  }
}
