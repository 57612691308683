import { Component, OnInit } from '@angular/core';
import { routerTransition } from 'src/app/router.animations';
import { AuthService } from 'src/app/services/auth.service';
import { REPORT_DATA } from 'src/app/shared/constants/report-data';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
  animations: [routerTransition()],
})
export class CardsComponent implements OnInit {
  public MainMenu: any;
  public loading: boolean = false;
  public QuotesImage: any = {};
  public menuData: any;
  public subMenuData: any;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.GetQuotes();
    // this.GetStatus();
    const menu = localStorage.getItem('menu');
    const subMenu = localStorage.getItem('house_name');
    this.menuData = REPORT_DATA.find((o) => o?.key === menu);
    this.subMenuData = this.menuData.items?.find((o) => o?.key === subMenu);
  }
  GetStatus() {
    const obj = {
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
      token: localStorage.getItem('token'),
    };
    this.auth.growth_status(obj).subscribe(
      (res: any) => {
        this.MainMenu = res.Mainsubman;
        console.log(this.MainMenu, 'main menu');
      },
      (err: any) => {
        console.log(err, 'err');
      }
    );
  }
  GetQuotes() {
    this.loading = true;
    const obj = {
      token: localStorage.getItem('token'),
      dimensionmenu: localStorage.getItem('menu'),
      dimensionsubmenu: localStorage.getItem('house_name'),
    };
    this.auth.Quotes(obj).subscribe(
      (res: any) => {
        if (res.status == true) {
          let img = res.quotes[Math.floor(Math.random() * res.quotes.length)];
          this.QuotesImage = img;
          this.loading = false;
        }
      },
      (err: any) => {}
    );
  }

  reloadPage() {
    window.location.reload();
  }
}
