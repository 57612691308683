<section class="my-5" [@routerTransition] style="--menu-color: {{menuData.color}}">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-8 m-auto">
        <h3 class="text-dark font-weight-bold text-center">
          Inspirational Quotes for IDG Skill:
        </h3>
        <h3 class="font-weight-bold py-3 text-center sub-menu-text">
          {{ subMenuData?.name }}
        </h3>
        <ngb-carousel [interval]="1000000">
          <ng-template ngbSlide *ngFor="let image of QuotesImage">
            <div class="picsum-img-wrapper" [style.backgroundImage]="'url('+ image +')'">
              <!-- <img
                class="img-align"
                src="{{ image }}"
                alt="Random first slide"
              /> -->
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </div>
</section>
