import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dairy',
  templateUrl: './dairy.component.html',
  styleUrls: ['./dairy.component.scss']
})
export class DairyComponent implements OnInit {
  DiaryList:any=[];
  dairyloading: boolean = false;
  Continuecard:boolean = false;
  ContinuContent:any={};
  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.Continue();
    this.GetDiaryLog();
  }

  Continue(){
    const obj={
      token:localStorage.getItem('token'),
      userid: localStorage.getItem('user_id'),
      org_id: localStorage.getItem('org_id')
    }
    this.auth.LastDimension(obj).subscribe(
      (res:any)=>{
        if(res.status == true){
          this.Continuecard=true;
          this.ContinuContent=res.dimensionmenu[0];
        }
        else{
          this.Continuecard=false;
        }
      },
      (err:any)=>{
        this.Continuecard=false;
      }
    )
  }

  GetDiaryLog(){
    this.dairyloading = true;
    const obj={
      token:localStorage.getItem('token'),
      user_id:localStorage.getItem('user_id')
    }
    this.auth.CheckDiaryLog(obj).subscribe(
      (res:any)=>{
        if(res.status == true){
          this.DiaryList=res.data;
          this.dairyloading = false;
        } else {
          this.dairyloading = false;
        }
      },
      (err:any)=>{
        this.dairyloading = false;
      }
    )
  }

}
