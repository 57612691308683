import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
TranslateModule.forChild();
@Component({
  selector: 'app-question-type',
  templateUrl: './question-type.component.html',
  styleUrls: ['./question-type.component.scss']
})
export class QuestionTypeComponent implements OnInit {
  @Input() data;
  
  icon_show1: boolean = false;
  icon_show2: boolean = false;
  constructor(public activeModal: NgbActiveModal, private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
    console.log(this.data)
  }

 

  gotoSurvery(datas) {
    console.log(this.data)
      if(datas == 'individual') {
        this.icon_show1 = true;
      } else {
        this.icon_show2 = true;
      }
    const obj={
      userid: localStorage.getItem('user_id'),
      user_qus_type: datas,
      token: localStorage.getItem('token'),
      test_count:this.data.sect_id ===7?this.data.test_count+1:this.data.test_count,
    }
    this.auth.setQuestionType(obj).subscribe(
      (res:any)=>{
        if(res.status == true){
          this.activeModal.close();
          this.icon_show1 = false;
          this.icon_show2 = false;
          localStorage.setItem('user_qus_type', datas);
          this.router.navigate(['/survey/' + this.data.sect_id]);
        } else {
          this.icon_show1 = false;
          this.icon_show2 = false;
        }
      },
      (err:any)=>{
        this.icon_show1 = false;
        this.icon_show2 = false;
      }
    )
  }
}
