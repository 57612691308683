<div class="card mt-4 mt-md-0 mb-4">
  <div class="card-body">
    <div class="continue_card mb-3" *ngIf="Continuecard">
      <h5>{{'DIARY.TITLE' | translate}}s</h5>
      <div class="custom-card mb-3">
        <p class="font-600 font-14 mb-0">{{'DIARY.BEING' | translate}} {{ContinuContent.dimensionmenu}} :
          {{ContinuContent.dimensionsubmenu}}</p>
      </div>
    </div>
    <div class="">
      <h5>{{'DIARY.TITLE1' | translate}}</h5>
      <div *ngIf="dairyloading" class="text-center">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="custom-card mb-3" *ngFor="let diary of DiaryList">
        <p class="font-600 font-14 mb-0">{{diary.dimensionsubmenu}}
          <br /><small>{{diary.created_at | date:'dd/MM/yyy'}}</small></p>
        <p class="font-14 mb-3">{{'DIARY.BEING' | translate}} {{diary.dimensionmenu}}</p>
        <div *ngIf="diary.ans1">
          <p class="font-600 font-14 mb-0">{{'DIARY.REASON' | translate}}</p>
          <p class="font-14 mb-3">{{diary.ans1}}</p>
        </div>
        <div *ngIf="diary.message">
          <p class="font-600 font-14 mb-0">{{'DIARY.THINGS' | translate}}</p>
          <p class="font-14 mb-0">{{diary.message}}</p>
        </div>
      </div>
      <div *ngIf="DiaryList && DiaryList.length == 0 && !dairyloading">
        <p class="font-600 font-14 mb-0">{{'DIARY.NODIARY' | translate}}</p>
      </div>

    </div>

  </div>

</div>
