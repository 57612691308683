<div class="d-none d-lg-flex" style="margin-top: -3rem; margin-left: 4rem">
  <a class="link-text" [routerLink]="['/resources']">Resources ></a>
  <a class="link-text" [routerLink]="['/growth-challenge']">
    Growth Challenge >
  </a>
</div>
<section class="mt-5" [@routerTransition] style="--menu-color: {{menuData.color}}">
  <div class="container">
    <div class="row">
      <div class="col-12 col-xs-6">
        <h3 class="text-dark font-weight-bold text-center">
          Choose one of the following challenges for IDG Skill:
        </h3>
        <h3 class="font-weight-bold py-3 text-center sub-menu-text">
          {{ subMenuData?.name }}
          <!-- -{{ challengeLevel | translate }}-->
        </h3>
      </div>
    </div>
    <div class="row" style="justify-content: center">
      <div
        class="col-11 col-lg-3 cards m-3 py-3"
        *ngFor="let task of challengeTask"
        style="cursor: pointer"
      >
        <a
          (click)="navigate('./challenges', task.sub_challanging_id)"
          class="text-center text-dark"
        >
          <div class="p-2">
            <svg-icon
              src="assets/images/icon1.svg"
            ></svg-icon>
          </div>
          <div class="p-2">
            <h3 class="header-text-style text-dark mb-0">
              {{ task.sub_challanging_task }}
            </h3>
          </div>
        </a>
      </div>
    </div>
    <div *ngIf="loading" class="text-center">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</section>
