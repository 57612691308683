import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DairyRoutingModule } from './dairy-routing.module';
import { DairyComponent } from './dairy.component';
import { BfpTranslateModule } from 'src/app/bfp-translate/bfp-translate.module';


@NgModule({
  declarations: [DairyComponent],
  imports: [
    CommonModule,
    DairyRoutingModule,
    BfpTranslateModule,
  ],
  exports: [DairyComponent]
})
export class DairyModule { }
