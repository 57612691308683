<section
  [@routerTransition]
  class="mt-5"
  style="--menu-color: {{ menuData.color }}"
>
  <div *ngIf="loading" class="text-center">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <div class="container mt-0">
    <div class="row" style="margin-bottom: 10vh">
      <div class="col-12 col-md-10 m-auto mt-0">
        <h3 class="text-dark font-weight-bold text-center">
          Pick an Inspirational Card for IDG Skill:
        </h3>
        <h3 class="font-weight-bold py-3 text-center sub-menu-color">
          {{ subMenuData?.name }}
        </h3>

        <div class="m-1" style="display: flow-root">
          <button
            (click)="reloadPage()"
            style="float: right"
            class="btn btn-outline-dark rounded-pill"
          >
            Pick a New card
          </button>
        </div>
        <div class="custom-card">
          <div class="text-center" *ngIf="QuotesImage">
            <h5 class="font-weight-bold">
              {{ "RESOURCE.QUOTE" | translate }}
            </h5>
          </div>
          <div class="text-center">
            <img
              src="{{ QuotesImage.images_url }}"
              alt="Quotes"
              class="img-fluid img-fit"
              *ngIf="QuotesImage.images_url"
            />
          </div>

          <div class="text-justify p-3">
            <p class="text">{{ QuotesImage.description }}</p>
          </div>

          <div class="text-right" style="color: #394c6d">
            <i
              >Cards (including content) designed by Vanessa Jane Smith
              <a class="sub-menu-color" href="https://www.craftingconnection.com" target="_blank"
                >(www.craftingconnection.com)</a
              ></i
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
