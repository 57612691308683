<div class="modal-header view_school_header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="p-4">
    <div class="row">
      <div class="col-md-12 mb-3">
        <h6 class="modal-title">I want to assess my Inner Development in the context of</h6>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <button class="btn btn-dark btn-block" [disabled]="icon_show1" (click)="gotoSurvery('individual')">
            <span >My life in general</span>
          </button>
        </div>
      </div>
      <div class="col-md-12">
        <p class="font-500 font-14 text-center">( Or )</p>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <button class="btn btn-dark btn-block" [disabled]="icon_show2" (click)="gotoSurvery('organization')">
            <span >My professional life</span>
          </button>
        </div>

      </div>
    </div>
  </div>

</div>
